import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/src/content/ataskaita/2014-11-23_2015-01-05.md";
import React from 'react';
import {graphql} from 'gatsby';
import BlogPost from '../components/BlogPost';
import Helmet from '../components/Helmet';
import Layout from '../components/Layout';
const BlogPostTemplate = ({data, children}) => {
  const post = data.mdx;
  return React.createElement(Layout, null, React.createElement(Helmet, {
    title: post.frontmatter.title || post.fields.title
  }), React.createElement(BlogPost, {
    post: post
  }, children));
};
export const query = graphql`
  query ($slug: String) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
      }
      fields {
        slug
        title
      }
    }
  }
`;
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
